import apiClient from '@/services/axios'

export async function getData(page, perPage, columns, filter, order) {
  const envObject = filter.find(obj => obj.Column === 'Env' && obj.Values === 0)
  if (envObject !== undefined) {
    filter = filter.filter(obj => obj !== envObject)
  }
  const adTypeObject = filter.find(obj => obj.Column === 'AdType' && obj.Values === 0)
  if (adTypeObject !== undefined) {
    filter = filter.filter(obj => obj !== adTypeObject)
  }
  const deviceTypeObject = filter.find(obj => obj.Column === 'DeviceType' && obj.Values === 0)
  if (deviceTypeObject !== undefined) {
    filter = filter.filter(obj => obj !== deviceTypeObject)
  }
  return apiClient.post('/ortb/stats/zone', {
    Page: page,
    PerPage: perPage,
    Columns: columns,
    Order: order.filter(el => columns.includes(el.Column)),
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportData(columns, filter, order, page, perPage) {
  return apiClient.post('/ortb/stats/zone/csv', {
    Columns: columns,
    Order: order,
    Filter: filter,
    Page: page,
    PerPage: perPage,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
